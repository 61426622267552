import { ComponentProps, use, useEffect, useRef } from 'react';
import { Sphere } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { gsap } from 'gsap';
import { BackSide, Color, Fog, type ColorRepresentation, type ShaderMaterial } from 'three';

import { GradientBgSphereMaterial } from './GradientBgSphere.material';

type Props = ComponentProps<typeof Sphere> & {
  colors: [ColorRepresentation, ColorRepresentation];
  fog?: boolean;
};
function GradientBgSphere(props: Props) {
  const { colors = ['red', 'blue'], fog = false, ...restProps } = props;

  const scene = useThree((state) => state.scene);

  const material = useRef<ShaderMaterial>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (!material.current) return;

      const tl = gsap.timeline();

      const prevColor1: Color = material.current.uniforms.color1To.value;
      const prevColor2: Color = material.current.uniforms.color2To.value;

      if (!scene.fog) {
        scene.fog = new Fog(prevColor1, 0, 10);
      }
      if (!scene.background) {
        scene.background = new Color().copy(prevColor1);
      }

      material.current.uniforms.color1From.value.copy(prevColor1);
      material.current.uniforms.color2From.value.copy(prevColor2);

      material.current.uniforms.transition.value = 0;
      material.current.uniforms.color1To.value.set(colors[0]);
      material.current.uniforms.color2To.value.set(colors[1]);

      tl.fromTo(
        material.current.uniforms.transition,
        { value: 0 },
        {
          value: 1,
          duration: 1.5,
          delay: 1,
          onUpdate: () => {
            scene.fog!.color.lerpColors(
              material.current!.uniforms.color1From.value,
              material.current!.uniforms.color1To.value,
              material.current!.uniforms.transition.value
            );
            (scene.background as Color).copy(scene.fog!.color);
          },
        }
      );
    });

    return () => {
      ctx.kill();
    };
  }, [colors]);

  return (
    <Sphere {...restProps}>
      <gradientBgSphereMaterial
        ref={material}
        key={GradientBgSphereMaterial.key}
        side={BackSide}
        toneMapped={true}
        fog={fog}
      />
    </Sphere>
  );
}

export { GradientBgSphere };
