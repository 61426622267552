import { useProgress } from '@react-three/drei';
import clsx from 'clsx';

import { useWebglStore } from '@/store';

const TOTAL_ITEMS = 35;

function Loader() {
  // const [loaded, total, item] = useProgress((s) => [s.loaded, s.total, s.item]);
  const loaded = useProgress((s) => s.loaded);

  /**
   *  `total` is an unreliable value that jumps up as the page loads.
   *  Once you know how many total assets are to be loaded, set TOTAL_ITEMS
   *  and use the line commented out below
   */
  //
  // const progress = (Math.max(0, loaded) / total) * 100;

  const progress = (Math.max(0, loaded) / TOTAL_ITEMS) * 100;
  const isFullyLoaded = useWebglStore((s) => s.isFullyLoaded);
  // const isFullyLoaded = false;

  const percent = Math.round(progress);

  return (
    <div className="fixed inset-0 pointer-events-none text-sm z-50">
      <div
        className={clsx(
          'bg-[#eb0a52] w-full h-full text-white flex flex-col items-center justify-center',
          'transition-opacity duration-300 delay-500',
          !isFullyLoaded && 'opacity-100',
          isFullyLoaded && 'opacity-0 pointer-events-none'
        )}
      >
        <div
          className={clsx(
            'w-1/2 lg:w-1/5 max-w-2xl transition-all',
            'ease-in-out duration-300 delay-200',
            !isFullyLoaded && 'opacity-100',
            isFullyLoaded && '-translate-y-1/4 opacity-0'
          )}
        >
          <svg viewBox="0 0 500 294" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bg" opacity="0.4" fill="#eefd8a">
              <path d="M151.269 70.0315V49.3689L93.0024 50.1806C93.0024 50.1068 93.0024 142.498 93.0024 142.498L151.269 142.425V114.751H121.398V100.657L145.369 100.066V82.2815L114.981 82.134V70.4005L151.269 70.0315Z" />
              <path d="M265.589 158.586L264.778 122.574H189.622V43.1702H220.009L220.967 0L161.963 5.46084L165.135 161.833L265.589 158.586Z" />
              <path d="M380.942 170.615L406.462 170.541L405.65 133.569L366.929 133.643L366.191 50.7712L399.602 50.6974V3.68982H338.902L336.911 290.015H414.353V253.856H381.68L380.942 170.615Z" />
              <path d="M31.4169 177.846H4.71755L1.25106 293.115L72.941 294L73.236 244.779L36.1372 248.837L31.4169 177.846Z" />
              <path d="M128.257 263.006V239.613L161.226 239.17L160.857 212.899L121.177 212.604L121.546 190.391L152.523 188.842L152.67 157.184V157.11C152.67 157.184 90.1997 158.438 90.1997 158.438L87.0282 293.705H188.294L186.598 261.087C186.671 261.235 128.257 263.006 128.257 263.006Z" />
              <path d="M250.027 241.974L207.839 176.297L170.076 176.518L229.744 291.122L269.203 291.27L320.684 170.836L283.291 170.393L250.027 241.974Z" />
              <path d="M59.9601 46.5647L45.7254 43.1701C42.3326 42.3584 40.6363 40.7349 40.6363 38.2997C40.6363 35.9382 42.1851 34.7575 45.2091 34.7575H143.156V0.737915H30.0893C22.05 0.737915 15.7808 2.95177 11.2818 7.23189C6.78269 11.5858 4.49628 17.7108 4.49628 25.6807V51.2138C4.49628 56.896 8.11028 61.9141 13.5682 63.759L41.9639 73.131C44.8403 74.0903 46.8317 76.7469 46.9792 79.8463L48.3806 126.632C48.4543 128.477 46.9792 129.953 45.1353 129.953H4.57004V160.947H50.962C59.0013 160.947 65.6393 158.955 70.9496 154.97C76.85 150.542 79.8002 144.417 79.8002 136.742V70.6219C79.8002 63.021 78.6201 57.7816 76.26 55.0512C72.7935 51.2138 67.4094 48.4096 59.9601 46.5647Z" />
              <path d="M317.955 7.96984C311.981 3.17316 304.532 0.737915 295.608 0.737915H232.842L233.948 107.298L266.179 106.634L268.392 79.5512L286.462 156.151H324.372L307.261 79.1084L317.808 69.6626C324.077 64.0542 327.175 57.4126 327.175 49.6641V27.009C327.175 19.2605 324.151 12.9141 317.955 7.96984ZM294.428 47.1551L266.917 55.9367L265.221 27.009L292.879 28.7063C294.428 28.7801 295.608 29.887 295.681 32.765L296.345 45.0888C296.345 45.015 296.345 46.4171 294.428 47.1551Z" />
              <path d="M429.842 142.499H460.229L462.958 46.7124L498.434 47.229C498.287 47.229 498.36 3.68982 498.36 3.68982H411.403V47.229H428.957L429.842 142.499Z" />
              <path d="M497.697 248.837H463.917L461.114 157.184H427.334L428.883 292.745H500.426L497.697 248.837Z" />
            </g>
            <mask
              id="sl_mask"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              width="500"
              height="294"
              x="0"
              y="0"
            >
              <g id="clipped" fill="#dfff0b">
                <path d="M151.269 70.0315V49.3689L93.0024 50.1806C93.0024 50.1068 93.0024 142.498 93.0024 142.498L151.269 142.425V114.751H121.398V100.657L145.369 100.066V82.2815L114.981 82.134V70.4005L151.269 70.0315Z" />
                <path d="M265.589 158.586L264.778 122.574H189.622V43.1702H220.009L220.967 0L161.963 5.46084L165.135 161.833L265.589 158.586Z" />
                <path d="M380.942 170.615L406.462 170.541L405.65 133.569L366.929 133.643L366.191 50.7712L399.602 50.6974V3.68982H338.902L336.911 290.015H414.353V253.856H381.68L380.942 170.615Z" />
                <path d="M31.4169 177.846H4.71755L1.25106 293.115L72.941 294L73.236 244.779L36.1372 248.837L31.4169 177.846Z" />
                <path d="M128.257 263.006V239.613L161.226 239.17L160.857 212.899L121.177 212.604L121.546 190.391L152.523 188.842L152.67 157.184V157.11C152.67 157.184 90.1997 158.438 90.1997 158.438L87.0282 293.705H188.294L186.598 261.087C186.671 261.235 128.257 263.006 128.257 263.006Z" />
                <path d="M250.027 241.974L207.839 176.297L170.076 176.518L229.744 291.122L269.203 291.27L320.684 170.836L283.291 170.393L250.027 241.974Z" />
                <path d="M59.9601 46.5647L45.7254 43.1701C42.3326 42.3584 40.6363 40.7349 40.6363 38.2997C40.6363 35.9382 42.1851 34.7575 45.2091 34.7575H143.156V0.737915H30.0893C22.05 0.737915 15.7808 2.95177 11.2818 7.23189C6.78269 11.5858 4.49628 17.7108 4.49628 25.6807V51.2138C4.49628 56.896 8.11028 61.9141 13.5682 63.759L41.9639 73.131C44.8403 74.0903 46.8317 76.7469 46.9792 79.8463L48.3806 126.632C48.4543 128.477 46.9792 129.953 45.1353 129.953H4.57004V160.947H50.962C59.0013 160.947 65.6393 158.955 70.9496 154.97C76.85 150.542 79.8002 144.417 79.8002 136.742V70.6219C79.8002 63.021 78.6201 57.7816 76.26 55.0512C72.7935 51.2138 67.4094 48.4096 59.9601 46.5647Z" />
                <path d="M317.955 7.96984C311.981 3.17316 304.532 0.737915 295.608 0.737915H232.842L233.948 107.298L266.179 106.634L268.392 79.5512L286.462 156.151H324.372L307.261 79.1084L317.808 69.6626C324.077 64.0542 327.175 57.4126 327.175 49.6641V27.009C327.175 19.2605 324.151 12.9141 317.955 7.96984ZM294.428 47.1551L266.917 55.9367L265.221 27.009L292.879 28.7063C294.428 28.7801 295.608 29.887 295.681 32.765L296.345 45.0888C296.345 45.015 296.345 46.4171 294.428 47.1551Z" />
                <path d="M429.842 142.499H460.229L462.958 46.7124L498.434 47.229C498.287 47.229 498.36 3.68982 498.36 3.68982H411.403V47.229H428.957L429.842 142.499Z" />
                <path d="M497.697 248.837H463.917L461.114 157.184H427.334L428.883 292.745H500.426L497.697 248.837Z" />
              </g>
            </mask>
            <g mask="url(#sl_mask)">
              <rect
                id="fill"
                width="500"
                height="294"
                fill="#dfff0b"
                className="origin-bottom transition-transform duration-300"
                style={{ transform: `scaleY(${progress / 100})` }}
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}

export { Loader };
