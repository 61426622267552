/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

interface NonFunctionalsStore {
  deviceRotated: boolean;
  setDeviceRotated: (val: boolean) => void;
  windowTooSmall: boolean;
  setWindowTooSmall: (val: boolean) => void;
  windowTooLarge: boolean;
  setWindowTooLarge: (val: boolean) => void;
  webGLDisabled: boolean;
  setWebGLDisabled: (val: boolean) => void;
  deviceNotSupported: boolean;
  setDeviceNotSupported: (val: boolean) => void;
}

export const useBaseNonFunctionalsStore = create<NonFunctionalsStore>()(
  devtools(
    (set) => ({
      deviceRotated: false,
      setDeviceRotated: (val) => set(() => ({ deviceRotated: val })),

      windowTooSmall: false,
      setWindowTooSmall: (val) => set(() => ({ windowTooSmall: val })),

      windowTooLarge: false,
      setWindowTooLarge: (val) => set(() => ({ windowTooLarge: val })),

      webGLDisabled: false,
      setWebGLDisabled: (val) => set(() => ({ webGLDisabled: val })),

      deviceNotSupported: false,
      setDeviceNotSupported: (val) => set(() => ({ deviceNotSupported: val })),
    }),
    { name: 'NonFunctionalsStore' }
  )
);

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
export const useNonFunctionalsStore = <U>(selector: (state: NonFunctionalsStore) => U) => {
  return useBaseNonFunctionalsStore(selector, shallow);
};

export default useNonFunctionalsStore;
