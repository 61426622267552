/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

interface SplatStore {
  accumulating: boolean;
  invalidateAccumulation: () => void;
}

let timeout: NodeJS.Timeout | null = null;

export const useBaseSplatStore = create<SplatStore>()(
  devtools(
    (set) => ({
      accumulating: true,
      invalidateAccumulation: () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        set({ accumulating: false });
        timeout = setTimeout(() => {
          set({ accumulating: true });
        }, 20);
      },
    }),
    { name: 'SplatStore' }
  )
);

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
export const useSplatStore = <U>(selector: (state: SplatStore) => U) => {
  return useBaseSplatStore(selector, shallow);
};

export default useSplatStore;
