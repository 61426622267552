/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

interface AppStore {
  menuOpened: boolean;
  setMenuOpened: (menuOpened: boolean) => void;
}
const useBaseAppStore = create<AppStore>()(
  devtools(
    (set) => ({
      menuOpened: false,
      setMenuOpened: (val) => set({ menuOpened: val }),
    }),
    { name: 'AppStore' }
  )
);

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
export const useAppStore = <U>(selector: (state: AppStore) => U) => {
  return useBaseAppStore(selector, shallow);
};

export default useAppStore;
