import { WebGLRenderer } from 'three';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';

let _ktx2Loader: KTX2Loader | null = null;

export const getKTXLoader = (renderer: WebGLRenderer) => {
  if (!_ktx2Loader) {
    _ktx2Loader = new KTX2Loader();
    _ktx2Loader.detectSupport(renderer);
    _ktx2Loader.setTranscoderPath('/workers/basis/');
  }
  return _ktx2Loader;
};

export const disposeKTXLoader = () => {
  if (_ktx2Loader) {
    _ktx2Loader.dispose();
  }
  _ktx2Loader = null;
};
