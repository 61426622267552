/**
 * @export
 * @function Clamp.
 * @description Keeps a number between two values.
 * @param {Number} number - Number to clamp.
 * @param {Number} minimum - The smallest possible value.
 * @param {Number} maximum - The largest possible value.
 * @return {Number} The clamped value
 */
export const clamp = (number: number, minimum: number, maximum: number) =>
  Math.min(Math.max(number, minimum), maximum);

/**
 * @export
 * @function NumberInRange.
 * @description Function to determine if a number is between two number. It can be inclusive or
 * exclusive.
 */
export const numberInRange = ({
  min,
  max,
  val,
  inclusive,
}: {
  min: number;
  max: number;
  val: number;
  inclusive: boolean;
}) => (inclusive ? (val - min) * (val - max) <= 0 : (val - min) * (val - max) < 0);

// https://github.com/processing/p5.js/blob/f81bb70089eb0d9bef4d51515fb80a72daa04a44/src/math/calculation.js#L450
export function map(n: number, start1: number, stop1: number, start2: number, stop2: number) {
  // eslint-disable-next-line no-mixed-operators
  return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
}

/**
 * @export
 * @function wait
 * @description Wait a specific amount of time and resolve a promise.
 * @param {Number} [ms=0] - The number of milliseconds to wait before resolving the promise.
 * @returns {Promise} Promise object that will resolve after n milliseconds
 */
export const wait = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * @export
 * @function shuffleArray
 * @description shuffles the indicies of an array
 * @param {Array} array - The number of milliseconds to wait before resolving the promise.
 * @returns {void}
 */
export const shuffleArray = (array: [unknown]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};
