import React, { useEffect, useMemo, useRef, useState } from 'react';
import { extend, ReactThreeFiber, useFrame, useThree } from '@react-three/fiber';
import { HalfFloatType, LinearSRGBColorSpace, RGBAFormat, Vector2, WebGLRenderTarget } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { TAARenderPass } from 'three/examples/jsm/postprocessing/TAARenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import { useBaseSplatStore } from '@/store/_splat';

extend({ TAARenderPass, EffectComposer, RenderPass, OutputPass, UnrealBloomPass });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      tAARenderPass: ReactThreeFiber.Object3DNode<TAARenderPass, typeof TAARenderPass>;
      outputPass: ReactThreeFiber.Object3DNode<OutputPass, typeof OutputPass>;
      unrealBloomPass: ReactThreeFiber.Object3DNode<UnrealBloomPass, typeof UnrealBloomPass>;
    }
  }
}

export function TAAPass(props) {
  const {} = props;
  const composer = useRef<EffectComposer>(null);
  const { scene, gl, size, camera, viewport } = useThree();

  const taaPass = useRef<TAARenderPass>(null);
  const bloom = useRef<UnrealBloomPass>(null);

  // const aspect = useMemo(() => new Vector2(resolution, resolution), [resolution]);
  const aspect = useMemo(() => new Vector2(1, 1), []);

  const [target] = useState(() => {
    const t = new WebGLRenderTarget(size.width, size.height, {
      type: HalfFloatType,
      format: RGBAFormat,
      // colorSpace: ,
      colorSpace: LinearSRGBColorSpace,
      depthBuffer: true,
      stencilBuffer: false,
      anisotropy: 1,
    });
    t.samples = 2;
    return t;
  });

  useEffect(() => {
    if (!composer.current) return;
    composer.current.setSize(size.width, size.height);
    composer.current.setPixelRatio(viewport.dpr);
    if (!taaPass.current) return;
    taaPass.current.accumulate = false;
  }, [gl, size, viewport.dpr]);

  useFrame(() => {
    if (!composer.current || !taaPass.current) return;
    composer.current?.render();
    taaPass.current.accumulate = useBaseSplatStore.getState().accumulating;
  }, 1);

  return (
    <>
      {/*@ts-ignore*/}
      <effectComposer ref={composer} args={[gl, target]}>
        <tAARenderPass attach="passes-0" ref={taaPass} scene={scene} camera={camera} unbiased={false} />
        <unrealBloomPass
          ref={bloom}
          attach="passes-1"
          args={[aspect, 1, 1, 0]}
          threshold={1}
          strength={1}
          radius={1}
        />
        <outputPass attach="passes-2" />
      </effectComposer>
    </>
  );
}
