import { type LoadingManager } from 'three';

import { GaussianSplatLoader } from './GaussianSplatLoader';

class GaussianSplatLoaderSmall extends GaussianSplatLoader {
  constructor(manager: LoadingManager | undefined) {
    super(manager);
    this.detail = 0.3;
  }
}

export { GaussianSplatLoaderSmall };
