import React, { Suspense, useRef } from 'react';
import { Preload, Stats } from '@react-three/drei';
import { Canvas as R3FCanvas } from '@react-three/fiber';
import { Leva, useControls } from 'leva';
import { LinearSRGBColorSpace } from 'three';

import { useDebug } from '@/hooks/use-debug';
import { useHideCanvas } from '@/hooks/use-hide-canvas';
import { useWebglStore } from '@/store';
import device from '@/utils/device';
import { HDRBloom } from '../HDRBloom/HDRBloom';
import { SplatSegments } from '../SplatPeople/SplatSegments';
import { TAAPass } from '../TAAPass/TAAPass';
import { LoadedWatcher, PerformanceMonitor } from './Canvas.utils';

function BaseCanvas() {
  const debug = useDebug();
  const hideCanvas = useHideCanvas();
  const isFullyLoaded = useWebglStore((s) => s.isFullyLoaded);

  const canvas = useRef(null);

  const { useAlphaHash } = useControls({
    useAlphaHash: false,
  });

  if (hideCanvas) {
    return (
      <div className="fixed left-0 top-0 w-screen h-screen">
        <R3FCanvas>
          <LoadedWatcher />
        </R3FCanvas>
      </div>
    );
  }

  return (
    <>
      <Leva hidden={!isFullyLoaded || !debug} />
      <div className="fixed left-0 top-0 w-screen h-screen" ref={canvas}>
        <R3FCanvas
          flat={true}
          gl={{
            outputColorSpace: LinearSRGBColorSpace,
          }}
          eventSource={canvas} // rebind event source to a parent DOM element
          eventPrefix="client" // use clientX/Y for a scrolling page
          style={{
            pointerEvents: 'none', // delegate events to wrapper
          }}
          dpr={[1, 1]}
        >
          <Suspense>
            <LoadedWatcher />
            <PerformanceMonitor />
            <Preload />
            {debug && (
              <Stats
                showPanel={0}
                className="!left-1/2 -translate-x-1/2 !opacity-50 pointer-events-none [&_canvas]:pointer-events-auto"
              />
            )}
            {/* project elements */}
            <SplatSegments useAlphaHash={useAlphaHash} fog={device.detection.isMobile} />
            {useAlphaHash ? <TAAPass /> : <HDRBloom />}
            {/* end project elements */}
          </Suspense>
        </R3FCanvas>
      </div>
    </>
  );
}

const Canvas = React.memo(BaseCanvas);

export { Canvas };
