import React, { useEffect, useRef } from 'react';
import { addAfterEffect, addEffect } from '@react-three/fiber';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useWebglStore } from '@/store';
import { clamp } from '@/utils/basic-functions';
import { disposeKTXLoader } from '@/utils/webgl/ktx-loader';

export function LoadedWatcher() {
  const setFullyLoaded = useWebglStore((s) => s.setFullyLoaded);

  useEffect(() => {
    setFullyLoaded(true);
    disposeKTXLoader();
  }, [setFullyLoaded]);

  return null;
}

const MIN_TIER = 1;
const MAX_TIER = 4;

interface PerformanceMonitorStore {
  performanceTier: number;
  setPerformanceTier: (val: number) => void;
  stepDownTier: () => void;
}

export const usePerformanceMonitor = create<PerformanceMonitorStore>()(
  devtools(
    (set, get) => ({
      performanceTier: 4,
      setPerformanceTier: (tier) => set(() => ({ performanceTier: clamp(tier, MIN_TIER, MAX_TIER) })),
      stepDownTier: () =>
        set(() => ({ performanceTier: clamp(get().performanceTier - 1, MIN_TIER, MAX_TIER) })),
    }),
    { name: 'PerformanceTier' }
  )
);

export function PerformanceMonitor() {
  const beginTime = useRef(0);
  const prevTime = useRef(0);
  const frames = useRef(0);
  const fps = useRef(0);
  const slowFpsCounter = useRef(0);
  const _performanceTier = useRef(MAX_TIER);

  const [stepDownTier, performanceTier] = usePerformanceMonitor((state) => [
    state.stepDownTier,
    state.performanceTier,
  ]);

  useEffect(() => {
    _performanceTier.current = performanceTier;

    const begin = addEffect(() => {
      beginTime.current = (performance || Date).now();
    });
    const end = addAfterEffect(() => {
      if (_performanceTier.current <= MIN_TIER) return;

      frames.current++;

      const time = (performance || Date).now();

      if (time > prevTime.current + 1000) {
        fps.current = (frames.current * 1000) / (time - prevTime.current);

        // if FPS is less that 50 for more than 6 samples. Step down tier and reset
        if (fps.current < 50) {
          slowFpsCounter.current++;
          if (slowFpsCounter.current > 6) {
            stepDownTier();
            _performanceTier.current--;
            slowFpsCounter.current = 0;
          }
        } else {
          slowFpsCounter.current = 0;
        }

        prevTime.current = time;
        frames.current = 0;
      }
    });

    return () => {
      begin();
      end();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
