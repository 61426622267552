// Based on https://github.com/studio-freight/hamo/blob/main/src/hooks/use-debug.js

import { useMemo } from 'react';

const useDebug = () => {
  const debug = useMemo(
    () =>
      typeof window !== 'undefined'
        ? window.location.href.includes('#debug') || process.env.NODE_ENV === 'development'
        : false,
    []
  );
  return debug;
};

export { useDebug };
