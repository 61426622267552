import { useMemo } from 'react';

const useHideCanvas = () => {
  const hideCanvas = useMemo(
    () => (typeof window !== 'undefined' ? window.location.href.includes('#hidecanvas') : false),
    []
  );
  return hideCanvas;
};

export { useHideCanvas };
