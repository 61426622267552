/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

interface WebglStore {
  isFullyLoaded: boolean;
  setFullyLoaded: (val: boolean) => void;
}
const useBaseWebglStore = create<WebglStore>()(
  devtools(
    (set) => ({
      isFullyLoaded: false,
      setFullyLoaded: (val) => set({ isFullyLoaded: val }),
    }),
    { name: 'WebglStore' }
  )
);

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
export const useWebglStore = <U>(selector: (state: WebglStore) => U) => {
  return useBaseWebglStore(selector, shallow);
};

export default useWebglStore;
