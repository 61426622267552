import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';
import { Color, ColorRepresentation, UniformsLib, UniformsUtils, Vector2, Vector2Tuple } from 'three';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      gradientBgSphereMaterial: JSX.IntrinsicElements['shaderMaterial'] & GradientBgSphereMaterialType;
    }
  }
}

export type GradientBgSphereMaterialType = {
  color1From?: ColorRepresentation;
  color2From?: ColorRepresentation;
  color1To?: ColorRepresentation;
  color2To?: ColorRepresentation;
  verticalBounds?: Vector2Tuple;
  transition?: number;
};

export const GradientBgSphereMaterial = shaderMaterial(
  {
    color1From: new Color('white'),
    color2From: new Color('white'),
    color1To: new Color('white'),
    color2To: new Color('white'),
    verticalBounds: new Vector2(0.5, 0),
    transition: 0.0,

    ...UniformsUtils.clone(UniformsLib.fog),
  },
  /* glsl */ `
  varying vec2 vUv;

  uniform vec2 verticalBounds;

  #include <fog_pars_vertex>

  float map(float value, float min1, float max1, float min2, float max2) {
    return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
  }

  void main() {
    vUv = vec2(0.0, map(position.y, verticalBounds.x, verticalBounds.y, 0., 1.));
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.);
    gl_Position = projectionMatrix * mvPosition;
    #include <fog_vertex>
  }
  `,
  /* glsl */ `
  uniform vec3 color1From;
  uniform vec3 color2From;
  uniform vec3 color1To;
  uniform vec3 color2To;
  uniform float transition;

  varying vec2 vUv;

  #include <fog_pars_fragment>

  void main() {
    vec3 from = mix(color1From, color2From, vUv.y);
    vec3 to = mix(color1To, color2To, vUv.y);
    vec3 final = mix(from, to, transition);
    final = clamp(final, 0.0, 1.0);

    gl_FragColor = vec4(final, 1.0);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
    #include <fog_fragment>
  }
  `,
  (material) => {
    if (!material) return;
    material.extensions.derivatives = true;
  }
);

extend({ GradientBgSphereMaterial });
