import React, { useEffect, useMemo, useRef, useState } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { HalfFloatType, RGBAFormat, sRGBEncoding, Vector2, WebGLRenderTarget } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

extend({ UnrealBloomPass, EffectComposer, RenderPass, OutputPass });

/**
 * @typedef Props
 * @prop {number} [intensity]
 * @prop {number} [renderIndex]
 * @prop {number} [multisampling]
 * @prop {number} [resolution]
 */
/**
 * @param {Props} props
 */
export function HDRBloom(props) {
  const { renderIndex = 1, multisampling = 8, intensity = 1.3, resolution = 1 } = props;

  /** @type {React.MutableRefObject<EffectComposer | undefined>} */
  const composer = useRef();
  /** @type {React.MutableRefObject<UnrealBloomPass | undefined>} */
  const bloom = useRef();
  const { scene, gl, size, camera, viewport } = useThree();
  const aspect = useMemo(() => new Vector2(resolution, resolution), [resolution]);

  const [target] = useState(() => {
    const t = new WebGLRenderTarget(size.width, size.height, {
      type: HalfFloatType,
      format: RGBAFormat,
      encoding: sRGBEncoding,
      depthBuffer: true,
      stencilBuffer: false,
      anisotropy: 1,
    });
    t.samples = multisampling;
    return t;
  });

  useEffect(() => {
    if (!composer.current) return;
    composer.current.setSize(size.width, size.height);
    composer.current.setPixelRatio(viewport.dpr);
  }, [gl, size, viewport.dpr]);

  useEffect(() => {
    if (!target || !bloom.current) return;

    target.samples = multisampling;
    bloom.current.nMips = multisampling < 5 ? 2 : 5;
    bloom.current.highPassUniforms.smoothWidth.value = 0.3;
    bloom.current.materialHighPassFilter.needsUpdate = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multisampling]);

  useFrame(() => {
    composer.current?.render();
  }, renderIndex);

  return (
    <effectComposer ref={composer} args={[gl, target]}>
      <renderPass attach="passes-0" scene={scene} camera={camera} />
      <unrealBloomPass
        ref={bloom}
        attach="passes-1"
        args={[aspect, 1, 1, 0]}
        threshold={1}
        strength={intensity}
        radius={1}
      />
      <outputPass attach="passes-2" />
    </effectComposer>
  );
}
