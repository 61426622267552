import { Poppins } from '@next/font/google';
import clsx from 'clsx';
import type { AppProps } from 'next/app';

import '@/styles/index.scss';
import { Loader } from '@/components/ui/Loader/Loader';
import { Canvas } from '@/components/webgl/Canvas/Canvas';

export const poppins = Poppins({
  subsets: ['latin'],
  weight: ['800'],
  variable: '--font-poppins',
});

export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <div className={clsx('App', poppins.variable)}>
      <Loader />
      {/* <NonFunctionals /> */}
      <Canvas />

      <Component {...pageProps} />
    </div>
  );
}
