import { useEffect, useRef, useState } from 'react';
import { Box, Html, OrbitControls, PerspectiveCamera, useTexture } from '@react-three/drei';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { useControls } from 'leva';
import { ColorRepresentation, Group, MathUtils, Mesh, RepeatWrapping, Texture, Vector3 } from 'three';

import useSplatStore from '@/store/_splat';
import device from '@/utils/device';
import { GaussianSplatBgMaterial } from '../GaussianSplatCustomMaterials/GaussianSplatBgMaterial';
import { GaussianSplatPersonMaterial } from '../GaussianSplatCustomMaterials/GaussianSplatPersonMaterial';
import { GaussianSplatLoader } from '../GaussianSplatLoader/GaussianSplatLoader';
import { GaussianSplatLoaderSmall } from '../GaussianSplatLoader/GaussianSplatLoaderSmall';
import { type GaussianSplatObject } from '../GaussianSplatLoader/GaussianSplatObject';
import { GradientBgSphere } from '../GradientBgSphere/GradientBgSphere';

const ROT_FAC = (Math.PI * 2) / 14;
const DIST_FROM_CENTER = 1.8;

type Person = {
  url: string;
  data: {
    name: string;
  };
  bgUrl?: string;
  scanBounds?: [number, number];
  rotation?: [number, number, number];
};
const people: Person[] = [
  {
    url: '/models/gs_Nico_Aguilar-edit.cleaned.splat',
    rotation: [0, Math.PI, 0],
    data: { name: 'Nico Aguilar' },
  },
  {
    url: '/models/gs_Jason_Zada_3-edit.cleaned.splat',
    rotation: [0, Math.PI, 0],
    data: { name: 'Jason Zada' },
  },
  {
    url: '/models/gs_Alexander_Rae_2-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Alexander Rae' },
  },
  {
    url: '/models/gs_Dave_Clark-combined.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Dave Clark' },
  },
  {
    url: '/models/gs_Monica_Monique-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Monica Monique' },
  },
  {
    url: '/models/gs_Nathan_Browne_2-edit.cleaned.splat',
    rotation: [0, Math.PI, 0],
    data: { name: 'Nathan Browne' },
  },
  {
    url: '/models/gs_Trace_Slobotkin_2-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Trace Slobotkin' },
  },
  {
    url: '/models/gs_Nathan_Atkins_2-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Nathan Atkins' },
  },
  {
    url: '/models/gs_Steven_Steiner-edit.cleaned.splat',
    rotation: [0, Math.PI, 0],
    data: { name: 'Steven Steiner' },
  },
  {
    url: '/models/gs_Brian_Moore-edit.cleaned.splat',
    rotation: [0, Math.PI, 0],
    data: { name: 'Brian Moore' },
  },
  {
    url: '/models/gs_Justin_Hackney-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Justin Hackney' },
  },
  {
    url: '/models/gs_Brenda_Chen_2-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Brenda Chen' },
  },
  {
    url: '/models/gs_Max_Rusan_2-edit.cleaned.splat',
    rotation: [0, 0, 0],
    data: { name: 'Max Rusan' },
  },
  {
    url: '/models/gs_Ronnie_Allman-edit.cleaned.splat',
    rotation: [0, Math.PI, 0],
    data: { name: 'Ronnie Allman' },
  },
];

type SplatBg = {
  url: string;
  rotation: [number, number, number];
  bgColor: [ColorRepresentation, ColorRepresentation];
  activeFrom: number;
  activeTo: number;
};

const splatBgs: SplatBg[] = [
  {
    url: '/models/nature.cleaned.splat',
    rotation: [0, -3, 0],
    bgColor: ['#e8e8e8', '#ffffff'],
    activeFrom: 0,
    activeTo: 2,
  },
  {
    url: '/models/suburb.cleaned.splat',
    rotation: [0, -0.6, 0],
    bgColor: ['#c6d6f8', '#ffffff'],
    activeFrom: 3,
    activeTo: 9,
  },
  {
    url: '/models/city.cleaned.splat',
    rotation: [0, 1.9, 0],
    bgColor: ['#e0e5ef', '#ffffff'],
    activeFrom: 10,
    activeTo: 13,
  },
];

const INITIAL_ROTATION = -(Math.PI * 2) / 14;

export function SplatSegments(props: { useAlphaHash?: boolean; fog?: boolean }) {
  const { useAlphaHash = true, fog = false } = props;

  const invalidateAccumulation = useSplatStore((state) => state.invalidateAccumulation);
  const group = useRef<Group>(null);
  const target = useRef<Mesh>(null);
  const camera = useThree((state) => state.camera);
  const events = useThree((state) => state.events);

  const rotation = useRef(INITIAL_ROTATION);

  const [activePerson, setActivePerson] = useState(1);

  const controlProps = useControls({
    useOrbitControls: false,
    debugTransitionIn: { value: 1, min: 0, max: 1 },
    debugTransitionOut: { value: 0, min: 0, max: 1 },
    transitionInLength: { value: 4, min: 0, max: 10 },
    glowColor: '#d493ef',
    glowIntensity: { value: 4.1, min: 0, max: 5 },
  });

  const { useOrbitControls, ...personControlProps } = controlProps;

  const noiseTexture = useTexture('/textures/noiseTexture.png', (tex) => {
    if (Array.isArray(tex)) {
      tex.forEach((t) => {
        t.wrapS = t.wrapT = RepeatWrapping;
        t.updateMatrix();
        t.needsUpdate = true;
      });
    } else {
      tex.wrapS = tex.wrapT = RepeatWrapping;
      tex.updateMatrix();
      tex.needsUpdate = true;
    }
  });

  function updateRotation(delta: 1 | -1) {
    if (!group.current) return;
    rotation.current -= ROT_FAC * delta;
    setActivePerson((prev) => prev + delta);
    gsap.to(group.current.rotation, {
      y: rotation.current,
      duration: 1,
      ease: 'power4.out',
      onUpdate: invalidateAccumulation,
      onComplete: () => {
        if (!events) return;
        events.update?.();
      },
    });
  }

  useEffect(() => {
    if (group.current) {
      group.current.rotation.y = INITIAL_ROTATION;
    }
    const onKeyDown = (e) => {
      if (e.key === 'ArrowLeft' || e.key === 'a') {
        updateRotation(-1);
      } else if (e.key === 'ArrowRight' || e.key === 'd') {
        updateRotation(1);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  const position = useRef(new Vector3(0, 0, 0));

  useFrame(({ pointer }, delta) => {
    if (!target.current || useOrbitControls) return;
    position.current.set(pointer.x * 0.2, pointer.y * 0.2 - 0.2, -camera.position.z);

    target.current.position.set(
      MathUtils.damp(target.current.position.x, position.current.x, 2, delta),
      MathUtils.damp(target.current.position.y, position.current.y, 2, delta),
      MathUtils.damp(target.current.position.z, position.current.z, 2, delta)
    );
    camera.position.x = -target.current.position.x * 1;
    camera.position.y = -target.current.position.y * 1 + 0.2;
    camera.lookAt(target.current.position);
  });

  const loopedActive = MathUtils.euclideanModulo(activePerson, people.length);
  const bgActiveIndex = splatBgs.findIndex(
    (bg) => loopedActive >= bg.activeFrom && loopedActive <= bg.activeTo
  );
  const bgColors = bgActiveIndex !== -1 ? splatBgs[bgActiveIndex].bgColor : splatBgs[0].bgColor;

  return (
    <>
      {useOrbitControls && (
        <OrbitControls
          onChange={() => {
            invalidateAccumulation();
          }}
        />
      )}
      <Buttons clickLeft={() => updateRotation(-1)} clickRight={() => updateRotation(1)} />
      <Box args={[0.1, 0.1, 0.1]} position={[0, 0, 0]} ref={target} visible={false}></Box>
      <PerspectiveCamera
        position={[0, 0.3, 1.2]}
        rotation={[-0.2, 0, 0]}
        near={0.8}
        far={fog ? 8 : 50}
        fov={75}
        makeDefault
      ></PerspectiveCamera>
      <GradientBgSphere colors={bgColors} scale={fog ? 6 : 40} fog={fog} />
      <group ref={group} position={[0, -0.7, -DIST_FROM_CENTER]}>
        {people.map((person, index) => (
          <group key={person.url} rotation={[0, ROT_FAC * index, 0]}>
            <SplatPerson
              position={[0, 0, DIST_FROM_CENTER]}
              active={index === loopedActive}
              {...person}
              renderOrder={2}
            />
          </group>
        ))}
        {splatBgs.map((bg, index) => (
          <SplatBackground
            key={bg.url}
            url={bg.url}
            fog={fog}
            alphaHash={useAlphaHash}
            noiseTexture={noiseTexture}
            {...personControlProps}
            renderOrder={1}
            active={index === bgActiveIndex}
            rotation={bg.rotation}
          />
        ))}
      </group>
    </>
  );
}

function SplatPerson(
  props: {
    active?: boolean;
  } & Person &
    JSX.IntrinsicElements['group']
) {
  const { url, data, renderOrder, active = false, ...restProps } = props;

  const gl = useThree((state) => state.gl);
  const scene = useThree((state) => state.scene);

  const [hovered, setHovered] = useState(false);

  const object = useLoader(GaussianSplatLoader, url, (loader) => {
    loader.assignRenderer(gl, scene);
  }) as GaussianSplatObject;

  const group = useRef<Group>(null);

  useEffect(() => {
    if (renderOrder !== undefined && object.mesh) {
      object.mesh.renderOrder = renderOrder;
    }
  }, [renderOrder, object.mesh]);

  const showName = (device.detection.isMobile && active) || (!device.detection.isMobile && active && hovered);

  return (
    <group ref={group} {...restProps}>
      <Box
        args={[1, 1.2, 0.4]}
        position={[0, 0.7, 0]}
        visible={false}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      ></Box>
      <Html
        position={[0, 0.5, 0]}
        className="pointer-events-none"
        center
        calculatePosition={(el, camera, size) => [size.width / 2, size.height - size.height * 0.3]}
      >
        <h2
          className={clsx(
            'text-[6rem] tracking-tight leading-none text-center font-bold ',
            'uppercase text-[#dfff0b] drop-shadow-lg',
            'opacity-0 transition-opacity',
            showName && 'opacity-100'
          )}
        >
          {data.name}
        </h2>
      </Html>
      <group>
        {object.mesh && (
          <primitive object={object.mesh}>
            <gaussianSplatMaterial key={GaussianSplatPersonMaterial.key} />
          </primitive>
        )}
      </group>
    </group>
  );
}

function SplatBackground(
  props: {
    url: string;
    alphaHash?: boolean;
    active?: boolean;
    scanBounds?: [number, number];
    noiseTexture: Texture;
    debugTransitionIn?: number;
    debugTransitionOut?: number;
    transitionInLength?: number;
    glowColor?: ColorRepresentation;
    glowIntensity?: number;
    glowLength?: number;
    fog?: boolean;
  } & JSX.IntrinsicElements['group']
) {
  const {
    url,
    active = false,
    noiseTexture,
    scanBounds = [0, 15],
    renderOrder,
    alphaHash,
    debugTransitionIn = 0,
    debugTransitionOut = 0,
    transitionInLength = 2,
    glowColor,
    glowIntensity,
    glowLength,
    fog = false,
    ...restProps
  } = props;

  const commonShaderProps = {
    scanBounds,
    noiseMap: noiseTexture,
    transitionIn: debugTransitionIn,
    transitionOut: debugTransitionOut,
    glowColor,
    glowIntensity,
    glowLength,
    fog,
    toneMapped: false,
  };

  const gl = useThree((state) => state.gl);
  const scene = useThree((state) => state.scene);

  const invalidateAccumulation = useSplatStore((state) => state.invalidateAccumulation);

  const object = useLoader(GaussianSplatLoaderSmall, url, (loader) => {
    loader.assignRenderer(gl, scene);
    loader.detail = 0.3;
  }) as GaussianSplatObject;

  const group = useRef<Group>(null);

  useEffect(() => {
    if (!object.mesh) return;
    object.mesh.visible = false;
  }, [object.mesh]);

  useEffect(() => {
    if (!object.mesh || !object.mesh.material.uniforms.transitionIn) return;
    const ctx = gsap.context(() => {
      const tl = gsap.timeline();
      if (active) {
        tl.set(object.mesh, { visible: true });
        tl.set(object.mesh.material.uniforms.transitionOut, { value: 0 });
        tl.fromTo(
          object.mesh.material.uniforms.transitionIn,
          { value: 0 },
          {
            value: 1,
            duration: transitionInLength,
            ease: 'power1.in',
            onUpdate: invalidateAccumulation,
          }
        );
      } else {
        tl.fromTo(
          object.mesh.material.uniforms.transitionOut,
          { value: 0 },
          {
            value: 1,
            duration: transitionInLength,
            ease: 'power1.in',
            onUpdate: invalidateAccumulation,
            onComplete: () => {
              object.mesh.visible = false;
            },
          }
        );
      }
    });
    return () => {
      ctx.kill();
    };
  }, [active, transitionInLength, object.mesh, invalidateAccumulation]);

  useFrame((_, delta) => {
    if (object.mesh && object.mesh.material.uniforms.time) {
      object.mesh.material.uniforms.time.value += delta;
    }
  });

  useEffect(() => {
    if (renderOrder !== undefined && object.mesh) {
      object.mesh.renderOrder = renderOrder;
    }
  }, [renderOrder, object.mesh]);

  return (
    <group ref={group} {...restProps}>
      <group>
        {object.mesh && (
          <primitive object={object.mesh}>
            {!alphaHash && (
              <gaussianSplatBgMaterial key={GaussianSplatBgMaterial.key} {...commonShaderProps} />
            )}
            {alphaHash && (
              <gaussianSplatBgMaterial
                key={GaussianSplatBgMaterial.key + 'hash'}
                alphaHash={true}
                transparent={false}
                depthWrite={true}
                depthTest={true}
                {...commonShaderProps}
              />
            )}
          </primitive>
        )}
      </group>
    </group>
  );
}

function Buttons(props: { clickLeft: () => void; clickRight: () => void }) {
  return (
    <Html fullscreen transform={false} className="w-screen h-screen">
      <button
        className={clsx(
          'absolute left-10 lg:left-20 top-1/3 lg:top-1/2 -translate-y-1/2 pointer-events-all ',
          'w-14 lg:w-14 h-auto bg-transparent',
          'transition-colors duration-300 bg-opacity-10 transition-transform hover:scale-110'
        )}
        onClick={props.clickLeft}
      >
        <svg viewBox="0 0 49 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="11.582"
            y="47.7875"
            width="15.241"
            height="51.7361"
            transform="rotate(-135 11.582 47.7875)"
            fill="#EB0A52"
          />
          <rect
            x="0.804932"
            y="36.9896"
            width="15.241"
            height="51.7361"
            transform="rotate(-45 0.804932 36.9896)"
            fill="#EB0A52"
          />
        </svg>
      </button>
      <button
        className={clsx(
          'absolute right-10 lg:right-20 top-1/3 lg:top-1/2 -translate-y-1/2 pointer-events-all ',
          'w-14 lg:w-14 h-auto bg-transparent',
          'transition-colors duration-300 bg-opacity-10 transition-transform hover:scale-110',
          'rotate-180'
        )}
        onClick={props.clickRight}
      >
        <svg viewBox="0 0 49 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="11.582"
            y="47.7875"
            width="15.241"
            height="51.7361"
            transform="rotate(-135 11.582 47.7875)"
            fill="#EB0A52"
          />
          <rect
            x="0.804932"
            y="36.9896"
            width="15.241"
            height="51.7361"
            transform="rotate(-45 0.804932 36.9896)"
            fill="#EB0A52"
          />
        </svg>
      </button>
    </Html>
  );
}
