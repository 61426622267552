/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

interface WindowSizeStore {
  windowSize: {
    width: number;
    height: number;
  };
  isMobile: boolean;
  setWindowSize: (dimension: { width: number; height: number }) => void;
}
const useBaseWindowSizeStore = create<WindowSizeStore>()(
  devtools(
    (set) => ({
      windowSize: {
        width: 0,
        height: 0,
      },
      isMobile: false,
      setWindowSize: (dimension) => {
        set(() => ({
          windowSize: {
            width: dimension.width,
            height: dimension.height,
          },
          isMobile: dimension.width < 1024, // TODO: should be based on a setting var
        }));
      },
    }),
    { name: 'WindowSizeStore' }
  )
);

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
export const useWindowSizeStore = <U>(selector: (state: WindowSizeStore) => U) => {
  return useBaseWindowSizeStore(selector, shallow);
};

export default useWindowSizeStore;
